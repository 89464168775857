import { IApplicationState } from "../store/state/app-state";
import CryptoJS from "crypto-js";

export const loadStorage = (): IApplicationState => {
  const decryptedStore = localStorage.getItem("astrology-admin-state");
  try {
    if (decryptedStore === null) {
      return undefined;
    } else {
      const bytesStore = CryptoJS.AES.decrypt(
        decryptedStore,
        process.env.REACT_APP_CIPHER
      );
      const serializedStore = bytesStore.toString(CryptoJS.enc.Utf8);
      return JSON.parse(serializedStore);
    }
  } catch (error) {
    console.log('Error While Getting LocalStorage State!!!');
  }
};

export const saveStorage = (state: IApplicationState) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem(
      "astrology-admin-state",
      CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(serializedState),
        process.env.REACT_APP_CIPHER
      ).toString()
    );
  } catch (error) {
    console.log('Error While Saving LocalStorage State!!!');
  }
};
