const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;

export const Routing = {
  Login: `${PATH_PREFIX}/login`,
  ForgotPassword: `${PATH_PREFIX}/forgot-password`,

  Dashboard: `${PATH_PREFIX}/dashboard`,

  Customers: `${PATH_PREFIX}/customers`,
  CustomerCunsultantHistory: `${PATH_PREFIX}/customers/:id?`,

  Astrologers: `${PATH_PREFIX}/astrologers`,
  AstrologerCunsultantHistory: `${PATH_PREFIX}/astrologers/:id?`,

  Categories: `${PATH_PREFIX}/categories`,
  Languages: `${PATH_PREFIX}/languages`,
  
  Payment: `${PATH_PREFIX}/payment`,
  Withdrawal: `${PATH_PREFIX}/payment/withdrawal`,
  Transaction: `${PATH_PREFIX}/payment/transaction`,
  Commission: `${PATH_PREFIX}/payment/commission`,
  
  Analytics: `${PATH_PREFIX}/analytics`,
  CustomerAnalytics: `${PATH_PREFIX}/analytics/customer`,
  AstrologerAnalytics: `${PATH_PREFIX}/analytics/astrologer`,
  ServicesAnalytics: `${PATH_PREFIX}/analytics/services`,

  Settings: `${PATH_PREFIX}/settings`,
  Notifications: `${PATH_PREFIX}/settings/notifications`,
  Announcements: `${PATH_PREFIX}/settings/announcements`,
  ContactUs: `${PATH_PREFIX}/settings/contactUs`,
  AboutUs: `${PATH_PREFIX}/settings/aboutUs`,
  TermsAndConditions: `${PATH_PREFIX}/settings/termsAndConditions`,
  PrivacyPolicy: `${PATH_PREFIX}/settings/privacyPolicy`,
  ContentSlug: `/:id?`,

  Feedback: `${PATH_PREFIX}/feedback`,

  AstroStore: `${PATH_PREFIX}/astro-store`,
  ProductCategories: `${PATH_PREFIX}/astro-store/product-categories`,
  ProductSubCategories: `${PATH_PREFIX}/astro-store/product-categories/:id?/sub-category`,
  Products: `${PATH_PREFIX}/astro-store/products`,
  ProductDetails: `${PATH_PREFIX}/astro-store/products/:id?`,
  ProductAddEdit: `${PATH_PREFIX}/astro-store/products/manage/:id?`,
  
  ErrorPage: `${PATH_PREFIX}/error`,
  NotFound: `${PATH_PREFIX}/not-found`,
};
