import React, { lazy } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Routing } from "./routing";
import ProtectedRoute from "./ProtectedRoute";


const PublicLayout = lazy(() => import("../layout/PublicLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));

const Login = lazy(() => import("../pages/Account/Login"));
const ForgotPassword = lazy(() => import("../pages/Account/ForgotPassword"));

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Customers = lazy(() => import("../pages/Customers/Customers"));
const CustomerCunsultantHistory = lazy(() => import("../pages/Customers/CustomerCunsultantHistory"));
const Astrollogers = lazy(() => import("../pages/Astrologers/Astrologers"));
const AstrologerCunsultantHistory = lazy(() => import("../pages/Astrologers/AstrologerDetails"));
const Categories = lazy(() => import("../pages/Categories/Categories"));
const Languages = lazy(() => import("../pages/Languages/Languages"));
const Withdrawal = lazy(() => import("../pages/Payment/Withdrawal"));
const Transaction = lazy(() => import("../pages/Payment/Transaction"));
const Commission = lazy(() => import("../pages/Payment/Commission"));
const CustomerAnalytics = lazy(() => import("../pages/Analytics/CustomerAnalytics"));
const AstrologerAnalytics = lazy(() => import("../pages/Analytics/AstrologerAnalytics"));
const ServicesAnalytics = lazy(() => import("../pages/Analytics/ServicesAnalytics"));
const Notifications = lazy(() => import("../pages/Settings/Notifications"));
const ContactUs = lazy(() => import("../pages/Settings/ContactUs"));
const AboutUs = lazy(() => import("../pages/Settings/AboutUs"));
const TermsAndConditions = lazy(() => import("../pages/Settings/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("../pages/Settings/PrivacyPolicy"));
const ContentSlug = lazy(() => import("../shared/constants/InfoContent"));
const Feedback = lazy(() => import("../pages/Feedback/Feedback"));
const ProductCategories = lazy(() => import("../pages/AstroStore/ProductCategories/ProductCategories"));
const ProductSubCategories = lazy(() => import("../pages/AstroStore/ProductCategories/ProductSubCategories"));
const Products = lazy(() => import("../pages/AstroStore/Products/Products"));
const ProductForm = lazy(() => import("../pages/AstroStore/Products/ProductForm"));
const ProductDetails = lazy(() => import("../pages/AstroStore/Products/ProductDetails"));

const ErrorPage = lazy(() => import("../pages/Account/ErrorPage"));
const NotFound = lazy(() => import("../pages/Account/NotFound"));


const privateRoute = (Element: any, props?: any) => {
  return <ProtectedRoute element={props ? <Element {...props} /> : <Element />} />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={Routing.Login} />,
  },
  {
    path: Routing.Login,
    element: (<PublicLayout><Login /></PublicLayout>),
  },
  {
    path: Routing.ForgotPassword,
    element: (<PublicLayout><ForgotPassword /></PublicLayout>),
  },
  {
    path: Routing.Dashboard,
    element: <MainLayout>{privateRoute(Dashboard)}</MainLayout>,
  },
  {
    path: Routing.Customers,
    element: <MainLayout>{privateRoute(Customers)}</MainLayout>,
  },
  {
    path: Routing.CustomerCunsultantHistory,
    element: <MainLayout>{privateRoute(CustomerCunsultantHistory)}</MainLayout>,
  },
  {
    path: Routing.Astrologers,
    element: <MainLayout>{privateRoute(Astrollogers)}</MainLayout>,
  },
  {
    path: Routing.AstrologerCunsultantHistory,
    element: <MainLayout>{privateRoute(AstrologerCunsultantHistory)}</MainLayout>,
  },
  {
    path: Routing.Categories,
    element: <MainLayout>{privateRoute(Categories)}</MainLayout>,
  },
  {
    path: Routing.Languages,
    element: <MainLayout>{privateRoute(Languages)}</MainLayout>,
  },
  {
    path: Routing.Withdrawal,
    element: <MainLayout>{privateRoute(Withdrawal)}</MainLayout>,
  },
  {
    path: Routing.Transaction,
    element: <MainLayout>{privateRoute(Transaction)}</MainLayout>,
  },
  {
    path: Routing.Commission,
    element: <MainLayout>{privateRoute(Commission)}</MainLayout>,
  },
  {
    path: Routing.CustomerAnalytics,
    element: <MainLayout>{privateRoute(CustomerAnalytics)}</MainLayout>,
  },
  {
    path: Routing.AstrologerAnalytics,
    element: <MainLayout>{privateRoute(AstrologerAnalytics)}</MainLayout>,
  },
  {
    path: Routing.ServicesAnalytics,
    element: <MainLayout>{privateRoute(ServicesAnalytics)}</MainLayout>,
  },
  {
    path: Routing.Notifications,
    element: <MainLayout>{privateRoute(Notifications)}</MainLayout>,
  },
  {
    path: Routing.ContactUs,
    element: <MainLayout>{privateRoute(ContactUs)}</MainLayout>,
  },
  {
    path: Routing.AboutUs,
    element: <MainLayout>{privateRoute(AboutUs)}</MainLayout>,
  },
  {
    path: Routing.TermsAndConditions,
    element: <MainLayout>{privateRoute(TermsAndConditions)}</MainLayout>,
  },
  {
    path: Routing.PrivacyPolicy,
    element: <MainLayout>{privateRoute(PrivacyPolicy)}</MainLayout>,
  },
  {
    path: Routing.ContentSlug,
    element: (<PublicLayout><ContentSlug /></PublicLayout>),
  },
  {
    path: Routing.Feedback,
    element: <MainLayout>{privateRoute(Feedback)}</MainLayout>,
  },
  {
    path: Routing.ProductCategories,
    element: <MainLayout>{privateRoute(ProductCategories)}</MainLayout>,
  },
  {
    path: Routing.ProductSubCategories,
    element: <MainLayout>{privateRoute(ProductSubCategories)}</MainLayout>,
  },
  {
    path: Routing.Products,
    element: <MainLayout>{privateRoute(Products)}</MainLayout>,
  },
  {
    path: Routing.ProductAddEdit,
    element: <MainLayout>{privateRoute(ProductForm)}</MainLayout>,
  },
  {
    path: Routing.ProductDetails,
    element: <MainLayout>{privateRoute(ProductDetails)}</MainLayout>,
  },
  {
    path: Routing.ErrorPage,
    element: (<PublicLayout><ErrorPage /></PublicLayout>),
  },
  {
    path: "*",
    element: (<PublicLayout><NotFound /></PublicLayout>),
  },
]);

const AppRouting = () => {
  return <RouterProvider router={routes} />;
};

export default AppRouting;
