import React from 'react';
import { HashLoader } from 'react-spinners';
import './loader.scss';

const Spinner: React.FC = () => {
    return (
        <div className="loader-wrapper">
            <HashLoader color="#FCB400" />
        </div>
    );
};

export default Spinner;
