import React from 'react';
import { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRouting from './routes/AppRouting';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from './layout/Spinner';
import { NextUIProvider } from '@nextui-org/react';

function App() {
  return (
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
        <ToastContainer limit={3} autoClose={1000} className="toaster" hideProgressBar={true} />
          <NextUIProvider>
            <AppRouting />
          </NextUIProvider>
        </Suspense>
      </Provider>
  );
}

export default App;
